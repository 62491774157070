@charset "UTF-8";
/*------------------------------------
  Madrasthemes variables
------------------------------------*/
/* EOC */
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

 aside, footer, header, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333e48;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.875rem;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0D4DA1;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #333e48;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.2);
  text-align: left;
  caption-side: bottom;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "PT Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: #0062bd;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e7eaf3;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #1e2022;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1480px) {
  .container {
    max-width: 1430px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

 .col-wd-6,
.col-xl-auto, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-lg-8, .col-lg-7, .col-lg-5, .col-lg-4, .col-lg-3, .col-md, .col-md-12, .col-md-10, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-sm-6, .col,
.col-auto, .col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .order-xl-0 {
    order: 0;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
}
@media (min-width: 1480px) {
  .col-wd-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-wd-6 {
    margin-left: 50%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333e48;
}
.table tbody + tbody {
  border-top: 2px solid #ecedf2;
}

.table-bordered {
  border: 1px solid #ecedf2;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.34rem + 2px);
  padding: 0.67rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #818181;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dddddd;
  border-radius: 1.4rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #818181;
  background-color: #fff;
  border-color: #66afe9;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #DDD;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f8fafd;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #818181;
  background-color: #fff;
}

.form-control-sm {
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.125rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 1.3rem;
}

.form-control-lg {
  height: calc(1.5em + 2.25rem + 2px);
  padding: 1.125rem 1.125rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 2rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00c9a7;
  padding-right: calc(1.5em + 1.34rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.335rem);
  background-size: calc(0.75em + 0.67rem) calc(0.75em + 0.67rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.34rem);
  background-position: top calc(0.375em + 0.335rem) right calc(0.375em + 0.335rem);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #de4437;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #de4437;
  padding-right: calc(1.5em + 1.34rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.335rem);
  background-size: calc(0.75em + 0.67rem) calc(0.75em + 0.67rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-feedback {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.34rem);
  background-position: top calc(0.375em + 0.335rem) right calc(0.375em + 0.335rem);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .input-group {
    width: auto;
  }
}

.btn {
  display: inline-block;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #333e48;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.67rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.4rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #333e48;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 77, 161, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0a3c7e;
  border-color: #093672;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #093672;
  border-color: #083166;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #77838f;
  border-color: #77838f;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #65707b;
  border-color: #5f6a74;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 150, 160, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #77838f;
  border-color: #77838f;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f6a74;
  border-color: #5a636d;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 150, 160, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.btn-success:hover {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00967d;
  border-color: #008972;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-info {
  color: #1e2022;
  background-color: #00dffc;
  border-color: #00dffc;
}
.btn-info:hover {
  color: #fff;
  background-color: #00bdd6;
  border-color: #00b2c9;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 194, 219, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #1e2022;
  background-color: #00dffc;
  border-color: #00dffc;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00b2c9;
  border-color: #00a7bc;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 194, 219, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.btn-danger:hover {
  color: #fff;
  background-color: #cd2f22;
  border-color: #c22d20;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c22d20;
  border-color: #b72a1e;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}

.btn-light {
  color: #1e2022;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #1e2022;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 216, 218, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #1e2022;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #1e2022;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 216, 218, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #333e48;
  border-color: #333e48;
}
.btn-dark:hover {
  color: #fff;
  background-color: #232b32;
  border-color: #1e242a;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 91, 99, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #333e48;
  border-color: #333e48;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1e242a;
  border-color: #191e23;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 91, 99, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-blue:hover {
  color: #fff;
  background-color: #0a3c7e;
  border-color: #093672;
}
.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}
.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #093672;
  border-color: #083166;
}
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}

.btn-blue-cart {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-blue-cart:hover {
  color: #fff;
  background-color: #0a3c7e;
  border-color: #093672;
}
.btn-blue-cart:focus, .btn-blue-cart.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}
.btn-blue-cart.disabled, .btn-blue-cart:disabled {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-blue-cart:not(:disabled):not(.disabled):active, .btn-blue-cart:not(:disabled):not(.disabled).active, .show > .btn-blue-cart.dropdown-toggle {
  color: #fff;
  background-color: #093672;
  border-color: #083166;
}
.btn-blue-cart:not(:disabled):not(.disabled):active:focus, .btn-blue-cart:not(:disabled):not(.disabled).active:focus, .show > .btn-blue-cart.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}

.btn-white {
  color: #1e2022;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #1e2022;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 222, 222, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #1e2022;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #1e2022;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 222, 222, 0.5);
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.3rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 1rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #333e48;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7eaf3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #334141;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2022;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #8c98a4;
  white-space: nowrap;
}

.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
  z-index: 1;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control {
  margin-left: -1px;
}
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


.input-group-append {
  display: flex;
}

.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn {
  margin-left: -1px;
}

.input-group-append {
  margin-left: -1px;
}


.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.navbar > .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 0;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 1.4rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.alert {
  position: relative;
  padding: 0.92rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0;
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f5f5f5;
  border-radius: 0.3125rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #d8d8d8;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #333e48;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #000000;
  text-decoration: none;
  background-color: transparent;
}
.list-group-item-action:active {
  color: #000000;
  background-color: transparent;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.32rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e7eaf3;
}
.list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.close {
  float: right;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  color: #1e2022;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #1e2022;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
}
.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  margin: 0;
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.3125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.4375rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.popover-header {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #1e2022;
  background-color: #f8fafd;
  border-bottom: 1px solid #e4ecf7;
  border-top-left-radius: calc(0.4375rem - 1px);
  border-top-right-radius: calc(0.4375rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.75rem 1rem;
  color: #333e48;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.bg-success {
  background-color: #00c9a7 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00967d !important;
}

.bg-blue {
  background-color: #0D4DA1 !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #093672 !important;
}

.bg-red {
  background-color: #C80101 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #950101 !important;
}

.bg-lightorange {
  background-color: #F8EAD2 !important;
}

a.bg-lightorange:hover, a.bg-lightorange:focus,
button.bg-lightorange:hover,
button.bg-lightorange:focus {
  background-color: #f1d5a6 !important;
}

.bg-lightpink {
  background-color: #F8D2F7 !important;
}

a.bg-lightpink:hover, a.bg-lightpink:focus,
button.bg-lightpink:hover,
button.bg-lightpink:focus {
  background-color: #f1a6ef !important;
}

.bg-lightblue {
  background-color: #D2DCF8 !important;
}

a.bg-lightblue:hover, a.bg-lightblue:focus,
button.bg-lightblue:hover,
button.bg-lightblue:focus {
  background-color: #a6baf1 !important;
}

.bg-lightcyan {
  background-color: #D2F8F8 !important;
}

a.bg-lightcyan:hover, a.bg-lightcyan:focus,
button.bg-lightcyan:hover,
button.bg-lightcyan:focus {
  background-color: #a6f1f1 !important;
}

.bg-lightgreen {
  background-color: #EFF8D2 !important;
}

a.bg-lightgreen:hover, a.bg-lightgreen:focus,
button.bg-lightgreen:hover,
button.bg-lightgreen:focus {
  background-color: #dff1a6 !important;
}

.bg-gray-13 {
  background-color: #f8f8f8 !important;
}

a.bg-gray-13:hover, a.bg-gray-13:focus,
button.bg-gray-13:hover,
button.bg-gray-13:focus {
  background-color: #dfdfdf !important;
}

.bg-gray-14 {
  background-color: #eaeaea !important;
}

a.bg-gray-14:hover, a.bg-gray-14:focus,
button.bg-gray-14:hover,
button.bg-gray-14:focus {
  background-color: #d1d1d1 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.border {
  border: 1px solid #e7eaf3 !important;
}

.border-top {
  border-top: 1px solid #e7eaf3 !important;
}

.border-right {
  border-right: 1px solid #e7eaf3 !important;
}

.border-bottom {
  border-bottom: 1px solid #e7eaf3 !important;
}

.border-left {
  border-left: 1px solid #e7eaf3 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-lg {
  border-radius: 0.4375rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 768px) {
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}


.my-0dot5 {
  margin-top: 0.143rem !important;
}


.my-0dot5 {
  margin-bottom: 0.143rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}


.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}


.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}


.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}


.px-4 {
  padding-left: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}


.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}


.px-6 {
  padding-right: 2.5rem !important;
}


.px-6 {
  padding-left: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}


.px-7 {
  padding-right: 3rem !important;
}


.px-7 {
  padding-left: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.m-auto {
  margin: auto !important;
}


.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  
  .px-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .px-md-3 {
    padding-right: 1rem !important;
  }
  
  .px-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  
  .my-xl-0 {
    margin-top: 0 !important;
  }
  
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1480px) {
  .mr-wd-1 {
    margin-right: 0.25rem !important;
  }
  .pr-wd-6 {
    padding-right: 2.5rem !important;
  }
  
  .px-wd-8 {
    padding-right: 3.5rem !important;
  }
  
  .px-wd-8 {
    padding-left: 3.5rem !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}
@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0D4DA1 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #072b5a !important;
}

.text-secondary {
  color: #77838f !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545d66 !important;
}

.text-dark {
  color: #333e48 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #13171b !important;
}

.text-red {
  color: #C80101 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #7c0101 !important;
}

.text-gray-5 {
  color: #878787 !important;
}

a.text-gray-5:hover, a.text-gray-5:focus {
  color: #616161 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.2) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #97a4af;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .table {
    border-collapse: collapse !important;
  }
}
/*----------------------------------------------------------------------
  * Electro - Responsive Website Template version v2.0
  * Copyright 2019 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
@font-face {
  font-family: "font-electro";
  src: url("../fonts/font-electro.eot");
  src: url("../fonts/font-electro.eot?#iefix") format("embedded-opentype"), url("../fonts/font-electro.woff") format("woff"), url("../fonts/font-electro.ttf") format("truetype"), url("../fonts/font-electro.svg#font-electro") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "font-electro" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ec {
  display: inline-block;
  font: normal normal normal 14px/1 font-electro;
  font-size: inherit;
  text-rendering: auto;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ec-add-to-cart:before {
  content: "d";
}

.ec-favorites:before {
  content: "j";
}

.ec-search:before {
  content: "p";
}

.ec-shopping-bag:before {
  content: "q";
}

.ec-user:before {
  content: "u";
}

.ec-close-remove:before {
  content: "z";
}

.w-100 {
  width: 100% !important;
}
/*------------------------------------
  Default Styles
------------------------------------*/
main {
  position: relative;
}

p {
  color: #747474;
  line-height: 1.7;
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-weight: 500;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a,
.h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #1e2022;
}
h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover,
.h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #333e48;
}
h1.text-white > a, h2.text-white > a, h3.text-white > a, h4.text-white > a, h5.text-white > a, h6.text-white > a,
.h1.text-white > a, .h2.text-white > a, .h3.text-white > a, .h4.text-white > a, .h5.text-white > a, .h6.text-white > a {
  color: #fff;
}

strong {
  font-weight: 600;
}

svg {
  margin-bottom: -0.4375rem;
  vertical-align: baseline;
}

.fa, .fas {
  font-weight: 900;
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: #0D4DA1;
}

::selection {
  color: #fff;
  background-color: #0D4DA1;
}

/*------------------------------------
  Accessibility
------------------------------------*/

a:focus,
button:focus {
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}

.btn:focus, .btn.focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

/*------------------------------------
  Print styles
------------------------------------*/
@media print {
  header, footer, .btn {
    display: none;
  }
}
/*------------------------------------
  Skippy
------------------------------------*/

/*------------------------------------
  List Group
------------------------------------*/
.list-group {
  list-style: none;
}
.list-group .list-group {
  margin-left: 1rem;
}
.list-group .list-group-item {
  font-size: 0.875rem;
}
.list-group .active > .list-group-item {
  color: #0D4DA1;
}

/* Border Radius */

/* Transparent */
.list-group-transparent .list-group-item {
  border-color: transparent;
  background-color: transparent;
}

/* White */

/* Flush */
.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.list-group-flush .list-group-item.active {
  color: #0D4DA1;
  background-color: transparent;
  border-color: transparent;
}

/* Borderless */
.list-group-borderless .list-group-item {
  border: none;
}

/* Striped */

/*------------------------------------
  Links
------------------------------------*/
.link {
  display: inline-block;
}

/* Collapse */

/* Link Light */

/* Link Muted */

/* Link White */

/*------------------------------------
  Header Default
------------------------------------*/
.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}
.u-header__section {
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

/* Navbar */
.u-header__navbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
}

/* Navbar Space */
/* Navbar Brand */
.u-header__navbar-brand, .u-header__navbar-brand > img {
  padding-top: 0;
  padding-bottom: 0;
  width: 237px;
}
.u-header__navbar-brand-center {
  display: flex;
  align-items: center;
}
.u-header__navbar-brand-vertical {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

/* Navbar Collapse */

/* Navbar Link */

/* Nav Item */

/* Nav Link */

/* Sub Menu */

/* Promo */

/* Banner */

/* Product Banner */

/* Hide Content */

/*------------------------------------
  Header Helpers
------------------------------------*/
.u-header--invisible {
  display: none;
}
.u-header--moved-up {
  transform: translate3d(0, -100%, 0);
}
.u-header--faded {
  opacity: 0;
  visibility: hidden;
}
.u-header__section--hidden {
  position: relative;
}
.u-header.u-header--untransitioned {
  transition: none;
}
.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

/*------------------------------------
  Nav Alignments
------------------------------------*/
@media (min-width: 992px) {
  .u-header-left-aligned-nav .u-header__navbar-brand {
    margin-left: 0;
    margin-right: 3rem;
  }
}
/*------------------------------------
  Header Default
------------------------------------*/

/*------------------------------------
  Fullscreen
------------------------------------*/
 .u-fullscreen__overlay {
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
.u-fullscreen__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background-image: linear-gradient(150deg, rgba(45, 21, 130, 0.95) 0%, rgba(19, 111, 232, 0.95) 100%);
  background-repeat: repeat-x;
  transition-delay: 0.5s;
}

/*------------------------------------
  Header Fullscreen Style
------------------------------------*/

/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/

/*------------------------------------
  Header Submenu Background Colors
------------------------------------*/

/*------------------------------------
  Transparent Header
------------------------------------*/

/*------------------------------------
  Header On Scroll
------------------------------------*/

/*------------------------------------
  Header White Nav Links
------------------------------------*/

/*------------------------------------
  Navbar Expand
------------------------------------*/
.navbar-expand {
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .u-header__navbar-brand {
    padding-top: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-expand-sm .u-header__navbar-brand {
    padding-top: 0.5rem;
  }
}
@media (min-width: 992px) {
  [class*=navbar-expand]:not(.navbar-expand-xl) {
    flex-wrap: nowrap;
  }
}
/*------------------------------------
  Header Floating
------------------------------------*/
.u-header--floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2rem;
}
.u-header--floating.js-header-fix-moment {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
  margin-top: 0;
}
.u-header--floating .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}
@media (min-width: 576px) {
  .u-header--floating-sm {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-sm.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-sm .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
}
@media (min-width: 768px) {
  .u-header--floating-md {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-md.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-md .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
}
@media (min-width: 992px) {
  .u-header--floating-lg {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-lg.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-lg .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
}
@media (min-width: 1200px) {
  .u-header--floating-xl {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-xl.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-xl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
}

@media (max-width: 575.98px) {
  .u-header--floating-sm .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-sm .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .u-header--floating-md .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-md .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .u-header--floating-lg .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-lg .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .u-header--floating-xl .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-xl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}
.u-header--floating .u-header__section {
  box-shadow: none;
}
.u-header--floating .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  display: none;
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}
.u-header--abs-top {
  top: 0;
  bottom: auto;
}
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) {
  top: 100%;
  bottom: auto;
}
.u-header--abs-bottom:not(.js-header-fix-moment) {
  top: auto;
  bottom: 0;
}
.u-header--abs-bottom:not(.js-header-fix-moment) .slideInUp {
  animation-name: absoluteSlideInUp;
}
@media (min-width: 576px) {
  .u-header--abs-top-sm, .u-header--abs-bottom-sm, .u-header--abs-top-2nd-screen-sm {
    position: absolute;
  }
  .u-header--abs-top-sm {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .slideInUp {
    animation-name: absoluteSlideInUp;
  }
}
@media (min-width: 768px) {
  .u-header--abs-top-md, .u-header--abs-bottom-md, .u-header--abs-top-2nd-screen-md {
    position: absolute;
  }
  .u-header--abs-top-md {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .slideInUp {
    animation-name: absoluteSlideInUp;
  }
}
@media (min-width: 992px) {
  .u-header--abs-top-lg, .u-header--abs-bottom-lg, .u-header--abs-top-2nd-screen-lg {
    position: absolute;
  }
  .u-header--abs-top-lg {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .slideInUp {
    animation-name: absoluteSlideInUp;
  }
}
@media (min-width: 1200px) {
  .u-header--abs-top-xl, .u-header--abs-bottom-xl, .u-header--abs-top-2nd-screen-xl {
    position: absolute;
  }
  .u-header--abs-top-xl {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .slideInUp {
    animation-name: absoluteSlideInUp;
  }
}

[class*=u-header--abs] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

/* Disable box-shadow on mobile device */

@keyframes absoluteSlideInUp {
  from {
    transform: translate3d(0, -10px, 0);
  }
}
/*------------------------------------
  Header Sidebar
------------------------------------*/
.u-header-sidebar__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.625rem;
}
.u-header-sidebar__content {
  padding: 1.5rem 3rem 3rem;
}

/*------------------------------------
  Sticky Positions
------------------------------------*/
.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}
.u-header--sticky-top {
  bottom: auto;
  top: 0;
}
.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}
.u-header--sticky-bottom .slideInUp {
  animation-name: stickySlideInUp;
}
@media (min-width: 576px) {
  .u-header--sticky-top-sm, .u-header--sticky-bottom-sm {
    position: fixed;
  }
  .u-header--sticky-top-sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-sm .slideInUp {
    animation-name: stickySlideInUp;
  }
}
@media (min-width: 768px) {
  .u-header--sticky-top-md, .u-header--sticky-bottom-md {
    position: fixed;
  }
  .u-header--sticky-top-md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-md .slideInUp {
    animation-name: stickySlideInUp;
  }
}
@media (min-width: 992px) {
  .u-header--sticky-top-lg, .u-header--sticky-bottom-lg {
    position: fixed;
  }
  .u-header--sticky-top-lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-lg .slideInUp {
    animation-name: stickySlideInUp;
  }
}
@media (min-width: 1200px) {
  .u-header--sticky-top-xl, .u-header--sticky-bottom-xl {
    position: fixed;
  }
  .u-header--sticky-top-xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-xl .slideInUp {
    animation-name: stickySlideInUp;
  }
}

/* Disable box-shadow on mobile device */

@keyframes stickySlideInUp {
  from {
    transform: translate3d(0, -10px, 0);
  }
}
/*------------------------------------
  Header Center Aligned
------------------------------------*/

/*------------------------------------
  Header Collapse
------------------------------------*/
.u-header-collapse__nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.u-header-collapse__nav-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.u-header-collapse__nav-link {
  color: #333e48;
}
.u-header-collapse__nav-pointer {
  position: relative;
}
.u-header-collapse__nav-pointer::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 65%;
  font-weight: 900;
  content: "\f107";
  margin-left: auto;
  transition: 0.3s ease;
}
.u-header-collapse__nav-pointer.active::after {
  transform: rotate(-180deg);
}
.u-header-collapse__nav-link, .u-header-collapse__submenu-nav-link {
  display: flex;
  align-items: center;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.u-header-collapse__nav-link.active, .u-header-collapse__submenu-nav-link.active {
  color: #0D4DA1;
}
.u-header-collapse__submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}
.u-header-collapse__submenu .u-header-collapse__nav-list {
  border-left: 2px solid #0D4DA1;
  padding-left: 1rem;
}
.u-header-collapse__submenu-nav-link {
  color: #77838f;
}

/*------------------------------------
  Alert
------------------------------------*/

/*------------------------------------
  Avatar
------------------------------------*/
.u-avatar {
  width: 3.125rem;
  height: 3.125rem;
}

/*------------------------------------
  Avatar Bordered
------------------------------------*/

/*------------------------------------
  Badges
------------------------------------*/

/*------------------------------------
  Badge Sizes
------------------------------------*/
/* Extra Small */

/* Small */

/* Medium */

/* Bigger */

/*------------------------------------
  Badge Styles
------------------------------------*/
/* primary badge */

/* secondary badge */

/* success badge */

/* info badge */

/* warning badge */

/* danger badge */

/* light badge */

/* dark badge */

/* primary-darker badge */

/* primary-lighter badge */

/* blue badge */

/* red badge */

/* lightorange badge */

/* lightpink badge */

/* lightblue badge */

/* lightcyan badge */

/* lightgreen badge */

/* blue-cart badge */

/* cyan badge */

/* sale badge */

/* black badge */

/* green badge */

/* gray-1 badge */

/* gray-2 badge */

/* gray-3 badge */

/* gray-4 badge */

/* gray-5 badge */

/* gray-6 badge */

/* gray-7 badge */

/* gray-8 badge */

/* gray-9 badge */

/* gray-13 badge */

/* gray-14 badge */

/* gray-15 badge */

/* gray-16 badge */

/* gray-17 badge */

/* gray-18 badge */

/* white badge */

/* indigo badge */

/*------------------------------------
  Badge Positions
------------------------------------*/

/*------------------------------------
  Badge in Avatar Component
------------------------------------*/
/* Default Size Avatar */

/* Small Size Avatar */

/* Large Size Avatar */

/* Extra Large Size Avatar */

/*------------------------------------
  Badge in Icon Component
------------------------------------*/
/* Default Size Icon */

/* Small Size Icon */

/* Large Size Icon */

/*------------------------------------
  Buttons Bootstrap Style
------------------------------------*/
/* facebook */

/* google */

/* twitter */

/* instagram */

/* github */

/* gray */

/*------------------------------------
  Custom Buttons
------------------------------------*/
/* primary button :hover, :focus, :active effects */
.btn-primary[href]:hover, .btn-primary[href]:focus, .btn-primary[href]:active, .btn-primary[type]:hover, .btn-primary[type]:focus, .btn-primary[type]:active {
  box-shadow: 0 4px 11px rgba(13, 77, 161, 0.35);
}
.btn-primary:not(label.btn), .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-primary:not([href]), .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}

.btn-primary:hover {
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}

/* secondary button :hover, :focus, :active effects */
.btn-secondary[href]:hover, .btn-secondary[href]:focus, .btn-secondary[href]:active, .btn-secondary[type]:hover, .btn-secondary[type]:focus, .btn-secondary[type]:active {
  box-shadow: 0 4px 11px rgba(119, 131, 143, 0.35);
}
.btn-secondary:not(label.btn), .btn-secondary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-secondary:not([href]), .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #77838f;
  border-color: #77838f;
}

.btn-secondary:hover {
  background-color: #77838f;
  border-color: #77838f;
}

/* success button :hover, :focus, :active effects */
.btn-success[href]:hover, .btn-success[href]:focus, .btn-success[href]:active, .btn-success[type]:hover, .btn-success[type]:focus, .btn-success[type]:active {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}
.btn-success:not(label.btn), .btn-success:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-success:not([href]), .btn-success:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:hover {
  background-color: #00c9a7;
  border-color: #00c9a7;
}

/* info button :hover, :focus, :active effects */
.btn-info[href]:hover, .btn-info[href]:focus, .btn-info[href]:active, .btn-info[type]:hover, .btn-info[type]:focus, .btn-info[type]:active {
  box-shadow: 0 4px 11px rgba(0, 223, 252, 0.35);
}
.btn-info:not(label.btn), .btn-info:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-info:not([href]), .btn-info:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-info:hover {
  background-color: #00dffc;
  border-color: #00dffc;
}

/* warning button :hover, :focus, :active effects */

/* danger button :hover, :focus, :active effects */
.btn-danger[href]:hover, .btn-danger[href]:focus, .btn-danger[href]:active, .btn-danger[type]:hover, .btn-danger[type]:focus, .btn-danger[type]:active {
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}
.btn-danger:not(label.btn), .btn-danger:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-danger:not([href]), .btn-danger:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #de4437;
  border-color: #de4437;
}

.btn-danger:hover {
  background-color: #de4437;
  border-color: #de4437;
}

/* light button :hover, :focus, :active effects */
.btn-light[href]:hover, .btn-light[href]:focus, .btn-light[href]:active, .btn-light[type]:hover, .btn-light[type]:focus, .btn-light[type]:active {
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35);
}
.btn-light:not(label.btn), .btn-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-light:not([href]), .btn-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* dark button :hover, :focus, :active effects */
.btn-dark[href]:hover, .btn-dark[href]:focus, .btn-dark[href]:active, .btn-dark[type]:hover, .btn-dark[type]:focus, .btn-dark[type]:active {
  box-shadow: 0 4px 11px rgba(51, 62, 72, 0.35);
}
.btn-dark:not(label.btn), .btn-dark:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-dark:not([href]), .btn-dark:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #333e48;
  border-color: #333e48;
}

.btn-dark:hover {
  background-color: #333e48;
  border-color: #333e48;
}

/* primary-darker button :hover, :focus, :active effects */

/* primary-lighter button :hover, :focus, :active effects */

/* blue button :hover, :focus, :active effects */
.btn-blue[href]:hover, .btn-blue[href]:focus, .btn-blue[href]:active, .btn-blue[type]:hover, .btn-blue[type]:focus, .btn-blue[type]:active {
  box-shadow: 0 4px 11px rgba(13, 77, 161, 0.35);
}
.btn-blue:not(label.btn), .btn-blue:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue:not([href]), .btn-blue:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}

.btn-blue:hover {
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}

/* red button :hover, :focus, :active effects */

/* lightorange button :hover, :focus, :active effects */

/* lightpink button :hover, :focus, :active effects */

/* lightblue button :hover, :focus, :active effects */

/* lightcyan button :hover, :focus, :active effects */

/* lightgreen button :hover, :focus, :active effects */

/* blue-cart button :hover, :focus, :active effects */
.btn-blue-cart[href]:hover, .btn-blue-cart[href]:focus, .btn-blue-cart[href]:active, .btn-blue-cart[type]:hover, .btn-blue-cart[type]:focus, .btn-blue-cart[type]:active {
  box-shadow: 0 4px 11px rgba(13, 77, 161, 0.35);
}
.btn-blue-cart:not(label.btn), .btn-blue-cart:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue-cart:not([href]), .btn-blue-cart:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}

.btn-blue-cart:hover {
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}

/* cyan button :hover, :focus, :active effects */

/* sale button :hover, :focus, :active effects */

/* black button :hover, :focus, :active effects */

/* green button :hover, :focus, :active effects */

/* gray-1 button :hover, :focus, :active effects */

/* gray-2 button :hover, :focus, :active effects */

/* gray-3 button :hover, :focus, :active effects */

/* gray-4 button :hover, :focus, :active effects */

/* gray-5 button :hover, :focus, :active effects */

/* gray-6 button :hover, :focus, :active effects */

/* gray-7 button :hover, :focus, :active effects */

/* gray-8 button :hover, :focus, :active effects */

/* gray-9 button :hover, :focus, :active effects */

/* gray-13 button :hover, :focus, :active effects */

/* gray-14 button :hover, :focus, :active effects */

/* gray-15 button :hover, :focus, :active effects */

/* gray-16 button :hover, :focus, :active effects */

/* gray-17 button :hover, :focus, :active effects */

/* gray-18 button :hover, :focus, :active effects */

/* white button :hover, :focus, :active effects */
.btn-white[href]:hover, .btn-white[href]:focus, .btn-white[href]:active, .btn-white[type]:hover, .btn-white[type]:focus, .btn-white[type]:active {
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}
.btn-white:not(label.btn), .btn-white:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-white:not([href]), .btn-white:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  background-color: #fff;
  border-color: #fff;
}

/* indigo button :hover, :focus, :active effects */

/* facebook button :hover, :focus, :active effects */

/* google button :hover, :focus, :active effects */

/* twitter button :hover, :focus, :active effects */

/* instagram button :hover, :focus, :active effects */

/* github button :hover, :focus, :active effects */

/* gray button :hover, :focus, :active effects */

/*------------------------------------
  Custom Button Toggle
------------------------------------*/

/*------------------------------------
  Air Button Styles
------------------------------------*/
/* primary air */

/* secondary air */

/* success air */

/* info air */

/* warning air */

/* danger air */

/* light air */

/* dark air */

/* primary-darker air */

/* primary-lighter air */

/* blue air */
.btn-soft-blue {
  color: #0D4DA1;
  background: rgba(13, 77, 161, 0.1);
  border-color: transparent;
}
.btn-soft-blue[href]:hover, .btn-soft-blue[href]:focus, .btn-soft-blue[href]:active, .btn-soft-blue[href].active, .btn-soft-blue[type]:hover, .btn-soft-blue[type]:focus, .btn-soft-blue[type]:active, .btn-soft-blue[type].active {
  color: #fff;
  background: #0D4DA1;
  box-shadow: 0 4px 11px rgba(13, 77, 161, 0.35);
}
.btn-soft-blue[href].disabled, .btn-soft-blue[href]:disabled, .btn-soft-blue[type].disabled, .btn-soft-blue[type]:disabled {
  color: #0D4DA1;
  background-color: rgba(13, 77, 161, 0.1);
  box-shadow: none;
}
.btn-soft-blue[href]:not(:disabled):not(.disabled):active, .btn-soft-blue[href]:not(:disabled):not(.disabled).active, .show > .btn-soft-blue[href].dropdown-toggle, .btn-soft-blue[type]:not(:disabled):not(.disabled):active, .btn-soft-blue[type]:not(:disabled):not(.disabled).active, .show > .btn-soft-blue[type].dropdown-toggle {
  color: #fff;
  background-color: #0D4DA1;
}

.btn.btn-soft-blue {
  color: #0D4DA1;
}

/* red air */

/* lightorange air */

/* lightpink air */

/* lightblue air */

/* lightcyan air */

/* lightgreen air */

/* blue-cart air */

/* cyan air */

/* sale air */

/* black air */

/* green air */

/* gray-1 air */

/* gray-2 air */

/* gray-3 air */

/* gray-4 air */

/* gray-5 air */

/* gray-6 air */

/* gray-7 air */

/* gray-8 air */

/* gray-9 air */

/* gray-13 air */

/* gray-14 air */

/* gray-15 air */

/* gray-16 air */

/* gray-17 air */

/* gray-18 air */

/* white air */

/* indigo air */

/* facebook air */

/* google air */

/* twitter air */

/* instagram air */

/* github air */

/* gray air */

/*------------------------------------
  Button Sizes
------------------------------------*/
/* Wide */

/* Extra Small */

/* Icons */
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 0.8175rem;
  width: 2rem;
  height: 2rem;
}

/*------------------------------------
  Text Buttons Styles
------------------------------------*/
/* primary text button */

/* secondary text button */
.btn-text-secondary {
  color: #77838f;
  background: transparent;
}
.btn-text-secondary[href]:hover, .btn-text-secondary[href]:focus, .btn-text-secondary[href]:active, .btn-text-secondary[type]:hover, .btn-text-secondary[type]:focus, .btn-text-secondary[type]:active {
  color: #333e48;
}

/* success text button */

/* info text button */

/* warning text button */

/* danger text button */

/* light text button */

/* dark text button */

/* primary-darker text button */

/* primary-lighter text button */

/* blue text button */

/* red text button */

/* lightorange text button */

/* lightpink text button */

/* lightblue text button */

/* lightcyan text button */

/* lightgreen text button */

/* blue-cart text button */

/* cyan text button */

/* sale text button */

/* black text button */

/* green text button */

/* gray-1 text button */

/* gray-2 text button */

/* gray-3 text button */

/* gray-4 text button */

/* gray-5 text button */

/* gray-6 text button */

/* gray-7 text button */

/* gray-8 text button */

/* gray-9 text button */

/* gray-13 text button */

/* gray-14 text button */

/* gray-15 text button */

/* gray-16 text button */

/* gray-17 text button */

/* gray-18 text button */

/* white text button */

/* indigo text button */

/*------------------------------------
  Buttons
------------------------------------*/
.btn[href] {
  cursor: pointer;
}
.btn:not([href]):not([type]):not(:disabled):not(.disabled) {
  cursor: default;
}

label.btn {
  cursor: pointer !important;
}

/* Pill */

/* Icon */
.btn-icon {
  position: relative;
  line-height: 0;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}
.btn-icon__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Background Transparent */
[class*=btn-soft-].btn-bg-transparent {
  background-color: transparent;
}

/* Toggle */

/*------------------------------------
  Brand
------------------------------------*/
.brand {
  font-size: 2rem;
  font-weight: 700;
  margin-left: 0.5rem;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/

/*------------------------------------
  Card
------------------------------------*/
/* Background Color */

/* Frame */

/* Collapse */

/* IE image rendering fix */

/*------------------------------------
  Card Gutters
------------------------------------*/
/*------------------------------------
  Card Group - Break
------------------------------------*/
/*------------------------------------
  Sidebar Navigation
------------------------------------*/
/* Disable content revealing on page load */
.u-sidebar[aria-labelledby] {
  opacity: 0;
}
.u-sidebar[aria-labelledby][role=tabpanel] {
  opacity: 1;
}
.u-sidebar[aria-labelledby].u-unfold--css-animation, .u-sidebar[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}
.u-sidebar.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1002;
  width: 18.75rem;
  height: 100%;
  background-color: #fff;
  box-shadow: -13px 0 20px rgba(119, 131, 143, 0.08);
}
.u-sidebar__body, .u-sidebar__content {
  height: 100%;
}
.u-sidebar--left {
  right: auto;
  left: 0;
  box-shadow: 13px 0 20px rgba(119, 131, 143, 0.08);
}
.u-sidebar__scroller {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.u-sidebar__container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

/*------------------------------------
  Sidebar Account
------------------------------------*/

/* Holder */

/* Toggle */

/* List */

/* Footer Links */

/*------------------------------------
  Sidebar Shopping Cart
------------------------------------*/

/*------------------------------------
  Clients
------------------------------------*/

/*------------------------------------
  Close
------------------------------------*/

/*------------------------------------
  Divider
------------------------------------*/

/*------------------------------------
  Divider Sizes
------------------------------------*/

/*------------------------------------
  Divider Styles
------------------------------------*/
/* Text */

/*------------------------------------
  Vertical Divider
------------------------------------*/

/* Breakpoint */

/*------------------------------------
  Divider Sizes
------------------------------------*/
/* Extra Small */

/* Small */

/* Large */

/* Extra Large */

/*------------------------------------
  Dropdown
------------------------------------*/
/* Disable content revealing on page load */
.dropdown-unfold[aria-labelledby] {
  opacity: 0;
}
.dropdown-unfold[aria-labelledby][role=tabpanel] {
  display: block;
  opacity: 1;
}
.dropdown-unfold[aria-labelledby].u-unfold--css-animation, .dropdown-unfold[aria-labelledby].u-unfold--jquery-slide {
  display: block;
  opacity: 1;
}
.dropdown-unfold.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.dropdown-unfold.show {
  display: block;
  opacity: 1;
}

/* Menu */
.dropdown-menu {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.28);
}

/* Nav Link */
.dropdown-nav-link {
  color: #334141;
  font-weight: 300;
  font-size: 0.875rem;
}
.dropdown-nav-link:hover {
  color: #1e2022;
}

/* Item */
.dropdown-item {
  font-size: 0.875rem;
}
.dropdown-item:hover {
  color: #333e48;
}
.dropdown-item.active {
  color: #333e48;
}

/* Toggle */
.dropdown-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f107";
  margin-left: 0.5rem;
}

/* Dropdown Positions */
/*------------------------------------
  Dropdown Card
------------------------------------*/

/*------------------------------------
  Dropdown Sizes
------------------------------------*/
/*------------------------------------
  Form
------------------------------------*/

.form-control:focus {
  box-shadow: none;
  border-color: #66afe9;
}

/* Form Borderless */

/*------------------------------------
  Form Pill
------------------------------------*/

/*------------------------------------
  Form Sizes
------------------------------------*/
/* Default Size */

/* Extra Small Size */

/* Small Size */

/* Large Size */

/*------------------------------------
  Focus States
------------------------------------*/

.u-focus-state .input-group,
.u-focus-state .form-control:first-child:last-child {
  box-shadow: none;
}
.u-focus-state:focus,
.u-focus-state *:focus,
.u-focus-state .form-control {
  border-color: #66afe9;
}
.u-focus-state .form-control {
  box-shadow: none;
}

/*------------------------------------
  Success State
------------------------------------*/

/*------------------------------------
  Error State
------------------------------------*/

/*------------------------------------
  Bookmark Checkbox
------------------------------------*/

/*------------------------------------
  Checkbox Bordered
------------------------------------*/

/*------------------------------------
  Checkbox Switch
------------------------------------*/

/*------------------------------------
  Custom Checkbox
------------------------------------*/

/*------------------------------------
  File Attachment Button
------------------------------------*/

/*------------------------------------
  File Attachment Input
------------------------------------*/

/*------------------------------------
  File Attachment Link
------------------------------------*/

/*------------------------------------
  Google Map Interactive
------------------------------------*/

/*------------------------------------
  Go To
------------------------------------*/
.u-go-to {
  display: none;
  position: relative;
  z-index: 100;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #0D4DA1;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  opacity: 0.5;
  transition: 0.3s ease-out;
}
.u-go-to__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.u-go-to:hover, .u-go-to:focus:hover {
  color: #fff;
  opacity: 1;
}

/*------------------------------------
  Got to Section - Vertical Arrow
------------------------------------*/

/*------------------------------------
  Got to Section - Modern
------------------------------------*/

/*------------------------------------
  Got to Section - Wave
------------------------------------*/

/*------------------------------------
  Hamburgers
------------------------------------*/
.u-hamburger {
  font-size: inherit;
  line-height: 0;
  padding: 0.25rem;
}
.u-hamburger:hover .u-hamburger__inner, .u-hamburger:hover .u-hamburger__inner::before, .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #EEE;
}
.u-hamburger__box {
  width: 1.4rem;
  height: 0.975rem;
  display: inline-block;
  position: relative;
}
.u-hamburger__inner {
  display: block;
  top: 0.0625rem;
  margin: top, -0.0625rem;
}
.u-hamburger__inner, .u-hamburger__inner::before, .u-hamburger__inner::after {
  width: 1.4rem;
  height: 0.125rem;
  background-color: #FFF;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.u-hamburger__inner::before, .u-hamburger__inner::after {
  content: "";
  display: block;
}
.u-hamburger__inner::before {
  top: 0.425rem;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.u-hamburger__inner::after {
  top: 0.85rem;
}
.u-hamburger[aria-expanded=true] .u-hamburger__inner, .u-hamburger .is-active .u-hamburger__inner {
  transform: translate3d(0, 0.425rem, 0) rotate(45deg);
}
.u-hamburger[aria-expanded=true] .u-hamburger__inner::before, .u-hamburger .is-active .u-hamburger__inner::before {
  transform: rotate(-45deg) translate3d(-0.2rem, -0.3rem, 0);
  opacity: 0;
}
.u-hamburger[aria-expanded=true] .u-hamburger__inner::after, .u-hamburger .is-active .u-hamburger__inner::after {
  transform: translate3d(0, -0.85rem, 0) rotate(-90deg);
}

/*------------------------------------
  Hero v1
------------------------------------*/

/*------------------------------------
  Indicator Dots
------------------------------------*/

/*------------------------------------
  Indicator - Vertical Dashed
------------------------------------*/

/*------------------------------------
  Indicator Vertical Line
------------------------------------*/

/*------------------------------------
  Indicator Steps
------------------------------------*/

/*------------------------------------
  Media Player
------------------------------------*/

/* Colors */

/* Large Size */

/* Extra Large Size */

/* Positions */

/*------------------------------------
  Media Viewer
------------------------------------*/

/*------------------------------------
  Video Player
------------------------------------*/

/* Large Size */

/* Positions */
/*------------------------------------
  Mockup Browser v1
------------------------------------*/

/*------------------------------------
  Mockup Devices v1
------------------------------------*/

/*------------------------------------
  Devices v2
------------------------------------*/

/*------------------------------------
  Devices v3
------------------------------------*/

/*------------------------------------
  Mockup Devices v4
------------------------------------*/

/*------------------------------------
  Modal Window
------------------------------------*/

/*------------------------------------
  Modal Window - Facebook
------------------------------------*/

/*------------------------------------
  Page Preloader
------------------------------------*/

/*------------------------------------
  Pagination
------------------------------------*/

/*------------------------------------
  Navs
------------------------------------*/
.nav .nav-item:not(:first-child) {
  margin-left: 0.25rem;
}
.nav .nav-item:not(:last-child) {
  margin-right: 0.25rem;
}

/* White Color */

/* Box */

/* Nav Steps */

/*------------------------------------
  Blog Thumb Minimal
------------------------------------*/

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/

/*------------------------------------
  Paging Modern
------------------------------------*/

/*------------------------------------
  Popover
------------------------------------*/
.popover-header {
  font-weight: 500;
}

/*------------------------------------
  Quantity
------------------------------------*/

/*------------------------------------
  Search Form - Push Top
------------------------------------*/

/* Banner */

/*------------------------------------
  Search Form - Slide Down
------------------------------------*/

/*------------------------------------
  Slick
------------------------------------*/
.u-slick {
  position: relative;
}

.u-slick--transform-off.slick-transform-off .slick-track {
  transform: none !important;
}

.u-slick-thumb-progress {
  width: 110%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*------------------------------------
  Slick Equal Height
------------------------------------*/
.u-slick--equal-height .slick-list {
  height: 100%;
}
.u-slick--equal-height .slick-track {
  display: flex;
  height: 100%;
}
.u-slick--equal-height .slick-track .slick-slide {
  display: flex;
  height: auto;
}

/*------------------------------------
  Slick Gutters
------------------------------------*/
/* Gutters X */
.u-slick--gutters-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.u-slick--gutters-1 .slick-slide {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.u-slick--gutters-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.u-slick--gutters-2 .slick-slide {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.u-slick--gutters-3 {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.u-slick--gutters-3 .slick-slide {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

/*------------------------------------
  Slick Zoom
------------------------------------*/
.u-slick-zoom .slick-slide .u-slick-zoom__slide {
  transform: scale(0.9);
  transition: all 0.5s ease-in-out;
}
.u-slick-zoom .slick-center .u-slick-zoom__slide {
  transform: scale(1);
}

/*------------------------------------
  Slick Arrows
------------------------------------*/
.u-slick__arrow {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #0D4DA1;
  background-color: rgba(13, 77, 161, 0.1);
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s;
}
.u-slick__arrow:hover {
  color: #fff;
  background-color: #0D4DA1;
}
.u-slick__arrow-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.u-slick__arrow-inner--left {
  left: 0;
}
.u-slick__arrow-inner--right {
  right: 0;
}

/* Flat */
.u-slick__arrow--flat {
  color: #fff;
  background-color: rgba(13, 77, 161, 0.8);
}

/* Flat White */
.u-slick__arrow--flat-white {
  color: #1e2022;
  background-color: #fff;
}
.u-slick__arrow--flat-white:hover {
  color: #0D4DA1;
  background-color: #fff;
}

/* Offset */
@media (min-width: 992px) {
  .u-slick__arrow--offset.u-slick__arrow-inner--left {
    left: -2.5rem;
  }
  .u-slick__arrow--offset.u-slick__arrow-inner--right {
    right: -2.5rem;
  }
}

/* Vertical Center Alignment */
.u-slick__arrow-centered--y {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

/*------------------------------------
  Slick Arrows Classic
------------------------------------*/
.u-slick__arrow-classic {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s;
}
.u-slick__arrow-classic:hover {
  color: #fff;
  background-color: #0D4DA1;
}
.u-slick__arrow-classic-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.u-slick__arrow-classic-inner--left {
  left: 0;
}
.u-slick__arrow-classic-inner--right {
  right: 0;
}

/*------------------------------------
  Slick Paging
------------------------------------*/
.u-slick__paging {
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.u-slick__paging .u-paging__current {
  color: #fff;
  font-size: 3.390625rem;
  font-weight: 300;
  line-height: 1;
}
.u-slick__paging .u-paging__divider {
  position: relative;
  margin-right: 0.5rem;
  margin-left: 0.25rem;
}
.u-slick__paging .u-paging__divider::before {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.765625rem;
  content: "/";
}
.u-slick__paging .u-paging__total {
  font-size: 1.3125rem;
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Slick Pagination
------------------------------------*/
.u-slick__pagination {
  padding-left: 0;
}
.u-slick__pagination:not(.u-slick__pagination--block) {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.u-slick__pagination li {
  display: flex;
  pointer-events: all;
  margin: 0 0.25rem;
  cursor: pointer;
}
.u-slick__pagination li span {
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  box-sizing: border-box;
  background-color: #0D4DA1;
  border: 3px solid transparent;
  border-radius: 50%;
  transform: scale(0.6);
  transition: 0.3s;
}
.u-slick__pagination li.slick-active span {
  background-color: transparent;
  border-color: #0D4DA1;
  transform: scale(0.9);
}

/* White Version */
.u-slick__pagination--white li span {
  background-color: #fff;
}
.u-slick__pagination--white li.slick-active span {
  border-color: #fff;
}

/* Block */
.u-slick__pagination--block li {
  display: block;
}

/* Vertical Center Alignment */
.u-slick__pagination-centered--y {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@media (min-width: 992px) {
  /* Vertical Option */
  .u-slick__pagination--vertical-lg li {
    display: block;
    margin: 0.25rem 0;
  }
  /* Vertical Option Positions */
  .u-slick__pagination--vertical-pos-v1-lg {
    position: absolute;
    top: 50%;
    left: -15.7%;
    transform: translateY(-50%);
  }
}
/*------------------------------------
  Slick Pagination Modern
------------------------------------*/
.u-slick--pagination-modern {
  width: 100%;
  max-width: 12.5rem;
}
.u-slick--pagination-modern .slick-slide {
  cursor: pointer;
  opacity: 0.7;
  transform: scale(0.7);
  backface-visibility: hidden;
  transition: 0.3s;
}
.u-slick--pagination-modern .slick-current {
  opacity: 1;
  transform: scale(1);
}

/*------------------------------------
  Slick Pagination Interactive
------------------------------------*/
.u-slick--pagination-interactive__text {
  color: rgba(255, 255, 255, 0.7);
}
.u-slick--pagination-interactive .slick-slide {
  cursor: pointer;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}
.u-slick--pagination-interactive .slick-slide:hover {
  background: rgba(255, 255, 255, 0.15);
}
.u-slick--pagination-interactive .slick-slide:hover.slick-center {
  background: #fff;
}
.u-slick--pagination-interactive .slick-center {
  background: #fff;
}
.u-slick--pagination-interactive .slick-center .u-slick--pagination-interactive__title {
  color: #0D4DA1;
}
.u-slick--pagination-interactive .slick-center .u-slick--pagination-interactive__text {
  color: #77838f;
}

/*------------------------------------
  Spinner
------------------------------------*/

/*------------------------------------
  Stats Progress
------------------------------------*/

/*------------------------------------
  SVG
------------------------------------*/

/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/
/*------------------------------------
  Tables
------------------------------------*/

/*------------------------------------
  Timeline
------------------------------------*/

/*------------------------------------
  Sticky Blocks
------------------------------------*/

/*------------------------------------
  Background Gradients
------------------------------------*/

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/

/*------------------------------------
  Fill Colors
------------------------------------*/

/*------------------------------------
  Stop Colors
------------------------------------*/

/*------------------------------------
  Stroke Colors
------------------------------------*/

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

/*------------------------------------
  Background Soft Colors
------------------------------------*/

/*------------------------------------
  Borders-radius
------------------------------------*/

.rounded-right-pill {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.rounded-left-pill {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

/*------------------------------------
  Borders
------------------------------------*/

/*------------------------------------
  Position Spaces
------------------------------------*/
.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}
/*------------------------------------
  Content Centered
------------------------------------*/
/*------------------------------------
  Margin Spacing
------------------------------------*/

/* Gutters X */

/*------------------------------------
  Opacity
------------------------------------*/

/*------------------------------------
  Box Shadow
------------------------------------*/

/*------------------------------------
  Width
------------------------------------*/

.max-width-6 {
  max-width: 2.5rem !important;
}

/*------------------------------------
  Height
------------------------------------*/
/*------------------------------------
  SVG Dividers
------------------------------------*/

/*------------------------------------
  Typography Font Size
------------------------------------*/
/*------------------------------------
  Font Weight
------------------------------------*/

/*------------------------------------
  Line Height
------------------------------------*/

/*------------------------------------
  Text Colors
------------------------------------*/

/*------------------------------------
  Transform
------------------------------------*/

/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}
.transition-3d-hover:hover, .transition-3d-hover:focus {
  transform: translateY(-3px);
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-n1 {
  z-index: -1;
}

.z-index-2 {
  z-index: 2;
}

/*------------------------------------
  Animate.css
------------------------------------*/
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

/*------------------------------------
  Area Chart
------------------------------------*/

/*------------------------------------
  Cubeportfolio
------------------------------------*/

/* Load more disabled class */

/* Zoom overlay effect */

/*------------------------------------
  Hover Animation Effects
------------------------------------*/

/*------------------------------------
  Custombox
------------------------------------*/

/*------------------------------------
  Datatable
------------------------------------*/

/*------------------------------------
  Dropzone
------------------------------------*/

/*------------------------------------
  DatePicker
------------------------------------*/

/* Positions */

/*------------------------------------
  Dzsparallaxer
------------------------------------*/

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme .fancybox-bg {
  background-color: #333e48;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}

.u-blur-30 {
  filter: blur(30px);
}

/*------------------------------------
  Fileuploader
------------------------------------*/
/* Default Style */

/* Front Style */

/* Dragging State */

/*------------------------------------
  Range Slider
------------------------------------*/

/* Indicator */

/* Grid */

/*------------------------------------
  Select
------------------------------------*/

/*------------------------------------
  Slick
------------------------------------*/
[data-lazy] {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slick-cloned [data-scs-animation-in] {
  opacity: 0;
}

.u-slick {
  background: url(../svg/preloaders/circle-preloader.svg) no-repeat 50% 50%;
}
.u-slick .js-next,
.u-slick .js-prev {
  opacity: 0;
}
.u-slick.slick-initialized {
  background: none;
}
.u-slick.slick-initialized .js-slide,
.u-slick.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}
.u-slick.slick-initialized .js-next,
.u-slick.slick-initialized .js-prev {
  opacity: 1;
}
.u-slick .js-slide,
.u-slick .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}
.u-slick .js-slide:first-child,
.u-slick .js-thumb:first-child {
  height: auto;
}

/*------------------------------------
  Summernote Editor
------------------------------------*/

/*------------------------------------
  Tagsinput
------------------------------------*/

/*------------------------------------
  Typed.js
------------------------------------*/
/*------------------------------------
  Madrasthemes SCSS style
------------------------------------*/
/*------------------------------------
  Font Icon (Electro)
------------------------------------*/
/*------------------------------------
  Default Styles
------------------------------------*/
sub, sup {
  font-size: 60%;
}

strong {
  font-weight: 700;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.009rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
}

del {
  text-decoration: line-through;
}

.blockquote {
  padding: 2.5rem;
  background-color: #333e48;
  letter-spacing: -0.4px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .blockquote {
    padding: 6.25rem;
  }
}
.blockquote p {
  font-size: 1.313rem;
  font-style: italic;
  color: #fff;
  margin-bottom: 2.188rem;
  line-height: 1.875rem;
}
.blockquote cite {
  font-style: normal;
}

blockquote:not(.blockquote) {
  margin: 0 0 1.25rem 2.375rem;
}
blockquote:not(.blockquote)::before {
  color: #a7a7a7;
  content: "\f10d";
  float: left;
  font-family: FontAwesome;
  font-size: 1.563rem;
  margin-left: -2.313rem;
}
blockquote:not(.blockquote) p {
  font-size: 0.938rem;
  line-height: 1.563rem;
  font-style: italic;
}

/*------------------------------------
  Links
------------------------------------*/

.link-hover__brand {
  display: block;
  opacity: 0.5;
  filter: grayscale(1);
}
.link-hover__brand:hover {
  filter: none;
  opacity: 1;
}

/*------------------------------------
  Header Default
------------------------------------*/
/* Topbar Nav Item */
.u-header-topbar {
  border-bottom: 1px solid #e8e8e8;
}

/* Topbar Nav Item */
.u-header-topbar__nav-item + .u-header-topbar__nav-item {
  margin-left: 1.5rem;
}
.u-header-topbar__nav-item + .u-header-topbar__nav-item-border {
  margin-left: 0;
}
.u-header-topbar__nav-item + .u-header-topbar__nav-item-border::before {
  content: "|";
  color: #0b4289;
  margin: 0 0.875rem;
  -webkit-font-smoothing: antialiased;
}
.u-header-topbar__nav-item + .u-header-topbar__nav-item-border:not(.full-bg)::before {
  color: #cad5d5;
}

/* Topbar Nav Link */
.u-header-topbar__nav-link {
  font-size: 0.813rem;
  color: #334141;
}
.u-header-topbar__nav-link i {
  font-size: 1.12525rem;
}
.u-header-topbar__nav-link[href]:hover {
  color: #333e48;
}

/* Header-Menu */

/* Navbar Brand */
.u-header__navbar-brand, .u-header__navbar-brand > img {
  width: 125px;
}
@media (min-width: 1200px) {
  .u-header__navbar-brand, .u-header__navbar-brand > img {
    width: 237px;
  }
}

/* Secondary Menu Navbar Divider */

/* Vertical-Menu */

/*------------------------------------
  Navbar Expand
------------------------------------*/

/*------------------------------------
  Nav Alignments
------------------------------------*/
/*------------------------------------
  Header Sidebar
------------------------------------*/
.u-header-sidebar__content {
  padding: 1rem 2rem 2rem;
}

/*------------------------------------
  Header Collapse
------------------------------------*/
.u-header-collapse__nav-link, .u-header-collapse__submenu-nav-link {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/*------------------------------------
  Alert
------------------------------------*/

/*------------------------------------
  Search-Forms
------------------------------------*/

/*------------------------------------
  Buttons
------------------------------------*/
/* Add cart btn */
.btn-add-cart {
  width: 2.188rem;
  height: 2.188rem;
  background-color: #e6e6e6;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.1875rem;
}
.btn-add-cart i {
  font-size: 1.25rem;
}
.btn-add-cart:hover {
  background-color: #0D4DA1;
  color: #fff;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/

/*------------------------------------
  Card
------------------------------------*/
.product-item {
  position: relative;
  display: flex;
}
@media (min-width: 1200px) {
  .product-item:hover .product-item__inner:not(.remove-prodcut-hover) {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    box-shadow: 0px 0px 6px 0px rgba(1, 1, 1, 0.3);
    background-color: #fff;
    z-index: 1;
  }
}
.product-item__title {
  font-size: 0.749875rem;
  line-height: 0.875rem;
}
@media (min-width: 992px) {
  .product-item__title {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) {
  .product-item__title a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
@media (min-width: 1200px) {
  .product-item__title a {
    min-height: 2.25rem;
  }
}

/*------------------------------------
  Form
------------------------------------*/

/*------------------------------------
  Custom Checkbox
------------------------------------*/

/*------------------------------------
  Progress Bar
------------------------------------*/
.progress-bar {
  border-radius: 0.3125rem;
}

/*------------------------------------
  Pagination
------------------------------------*/

/*------------------------------------
  Sidebar Navigation
------------------------------------*/

/*------------------------------------
  Slick Pagination
------------------------------------*/
.u-slick__pagination--long li {
  margin: 0 0.4375rem;
}
.u-slick__pagination--long li:first-child {
  margin-left: 0;
}
.u-slick__pagination--long li span {
  transform: none;
  border: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bcbcbc;
}
.u-slick__pagination--long li.slick-active span {
  transform: none;
  width: 1.875125rem;
  background-color: #0D4DA1;
  border-radius: 6.1875rem;
}

.u-slick__pagination--hover {
  position: absolute;
  opacity: 0;
  left: 2.25rem;
  bottom: -2.125rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .u-slick__pagination--hover {
    transition: none;
  }
}

.u-slick:hover .u-slick__pagination--hover {
  opacity: 1;
  bottom: 2.125rem;
}

/*------------------------------------
  Slick
------------------------------------*/
.u-slick-overflow-visble .slick-list {
  overflow: visible;
}

.u-slick-thumb-progress__custom .slick-slide {
  padding: 1rem 0.5rem;
  line-height: 1.2;
  display: none;
}
@media (min-width: 768px) {
  .u-slick-thumb-progress__custom .slick-slide {
    display: block;
  }
}
@media (min-width: 1200px) {
  .u-slick-thumb-progress__custom .slick-slide {
    padding: 1rem 2.6rem;
  }
}
@media (min-width: 1480px) {
  .u-slick-thumb-progress__custom .slick-slide {
    padding: 1rem 4.6rem;
  }
}
.u-slick-thumb-progress__custom .slick-slide .js-slick-thumb-progress {
  color: #334141;
  font-weight: 700;
}
.u-slick-thumb-progress__custom .slick-slide:not(.slick-current) .js-slick-thumb-progress {
  opacity: 0.4;
}
.u-slick-thumb-progress__custom .slick-slide:not(.slick-current) .js-slick-thumb-progress:hover {
  color: #7b8186;
  opacity: 1;
}
.u-slick-thumb-progress__custom .slick-current.js-slide {
  position: relative;
}
.u-slick-thumb-progress__custom .slick-current.js-slide::before, .u-slick-thumb-progress__custom .slick-current.js-slide::after {
  position: absolute;
  content: " ";
  border-top: 2px solid #0D4DA1;
}
.u-slick-thumb-progress__custom .slick-current.js-slide::before {
  display: block;
  top: 0;
  width: 100%;
  left: 0;
}
.u-slick-thumb-progress__custom .slick-current.js-slide::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 4px;
  left: 50%;
  border-top-width: 4px;
  transform: translate(-50%, -50%);
}

.u-slick--slider-syncing .slick-slide {
  padding: 0.625rem;
  border: 1px solid #eaeaea;
}
.u-slick--slider-syncing .slick-slide.slick-current {
  border-bottom-width: 2px;
  border-bottom-color: #0D4DA1;
}
@media (min-width: 1200px) {
  .u-slick--slider-syncing-size .slick-slide {
    width: 5rem !important;
  }
}

/*------------------------------------
  Slick Arrows
------------------------------------*/
.u-slick__arrow-normal {
  cursor: pointer;
}
.u-slick__arrow-normal.slick-disabled, .u-slick__arrow-normal.slick-disabled.js-next, .u-slick__arrow-normal.slick-disabled.js-prev {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .u-slick-deal-arrow__border::after {
    content: " ";
    position: absolute;
    height: 1px;
    width: 310px;
    right: 14px;
    top: 46px;
    background-color: #eaeaea;
  }
  .u-slick-deal-arrow::after {
    font-family: "PT Sans", sans-serif;
    color: #383838;
    font-size: 0.875rem;
    font-weight: normal;
  }
  .u-slick-deal-arrow__left::after {
    content: "Previous Deal";
    margin-left: 6px;
  }
  .u-slick-deal-arrow__right::after {
    content: "Next Deal";
    order: -1;
    margin-right: 6px;
  }
}

/*------------------------------------
  Tables
------------------------------------*/

/*------------------------------------
  Navs
------------------------------------*/
.nav .nav-item:not(:first-child) {
  margin-left: 0.4rem;
}
.nav .nav-item:not(:last-child) {
  margin-right: 0.4rem;
}

/* Box */

/*------------------------------------
  Typography Font Size
------------------------------------*/

.font-size-12 {
  font-size: 0.749875rem;
}

.font-size-13 {
  font-size: 0.812875rem;
}

.font-size-15 {
  font-size: 0.938rem;
}

.font-size-16 {
  font-size: 1.000125rem;
}

.font-size-17 {
  font-size: 1.06225rem;
}

.font-size-18 {
  font-size: 1.12525rem;
}

.font-size-20 {
  font-size: 1.250375rem;
}

.font-size-22 {
  font-size: 1.374625rem;
}

.font-size-24 {
  font-size: 1.49975rem;
}

.font-size-28 {
  font-size: 1.75rem;
}

.font-size-50 {
  font-size: 1.75rem;
}
@media (min-width: 768px) {
  .font-size-50 {
    font-size: 3.124625rem;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .font-size-20-lg {
    font-size: 1.25rem;
  }
}
.section-title {
  position: relative;
}
@media (max-width: 575.98px) {
  .section-title {
    font-size: 1.06225rem;
    font-weight: 700;
  }
}
.section-title::after {
  content: " ";
  height: 2px;
  width: 114px;
  display: block;
  background-color: #0D4DA1;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.section-title__full::after {
  width: 100%;
}

.prodcut-price {
  font-size: 1.000125rem;
}
@media (min-width: 992px) {
  .prodcut-price {
    font-size: 1.250375rem;
  }
}

/*------------------------------------
  Font Weight
------------------------------------*/

/*------------------------------------
  Line Height
------------------------------------*/
.text-lh-1 {
  line-height: 1;
}

.text-lh-1dot2 {
  line-height: 1.2;
}

.text-lh-28 {
  line-height: 1.75rem;
}

/*------------------------------------
  letter Space
------------------------------------*/

/*------------------------------------
  Others
------------------------------------*/

/*------------------------------------
  Text Colors
------------------------------------*/

.text-gray-90 {
  color: #333e48;
}
.text-gray-90[href]:hover {
  color: #333e48;
}

.text-gray-100 {
  color: #343f49;
}

.text-red {
  color: #C80101;
}

/*------------------------------------
  Borders-width
------------------------------------*/

.border-width-2 {
  border-width: 2px !important;
}

/*------------------------------------
  Borders-style
------------------------------------*/

/*------------------------------------
  Borders-radius
------------------------------------*/

/*------------------------------------
  Borders-Color
------------------------------------*/
.border-color-1 {
  border-color: #dddddd !important;
}
@media (max-width: 991.98px) {
  .border-md-down-top-0 {
    border-top: 0 !important;
  }
  .border-md-down-bottom-0 {
    border-bottom: 0 !important;
  }
}
.product-item {
  border-bottom: 1px solid #eaeaea;
}
@media (min-width: 768px) {
  .product-item {
    border-width: 0;
  }
}
.product-item:last-child, .product-item:nth-last-child(2) {
  border-width: 0;
}
.product-item::after {
  content: "";
  border-right: 1px solid #eaeaea;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  height: 70%;
  transform: translateY(-50%);
}
@media (min-width: 1200px) {
  .product-item::after {
    height: 80%;
  }
}
@media (min-width: 1200px) {
  .product-item__card::after {
    height: 70%;
  }
}
@media (max-width: 767.98px) {
  .product-item__card::after {
    content: none;
  }
}
.product-item:nth-child(even)::after {
  content: none;
}
@media (min-width: 768px) {
  .product-item:nth-child(even)::after {
    content: " ";
  }
}
.product-item.remove-divider::after {
  content: none;
}

/*------------------------------------
  Background Soft Colors
------------------------------------*/

/*------------------------------------
  Width
------------------------------------*/

.width-22 {
  width: 1.374625rem;
}

.max-width-120 {
  max-width: 7.5rem;
}
.max-width-148 {
  max-width: 9.25rem;
}

@media (min-width: 1200px) {
  .max-width-270 {
    max-width: 16.875rem;
  }
}

@media (min-width: 1200px) {
  .min-width-270 {
    min-width: 16.875rem;
  }
}

.max-width-334 {
  max-width: 100%;
}
@media (min-width: 1200px) {
  .max-width-334 {
    max-width: 20.375rem;
  }
}
@media (min-width: 1480px) {
  .max-width-334 {
    max-width: 20.875rem;
  }
}

/*------------------------------------
  Height
------------------------------------*/

.height-22 {
  height: 1.374625rem;
}

.height-40 {
  height: 2.562875rem;
}

.min-height-564 {
  min-height: 35.25025rem;
}

/*------------------------------------
  Box Shadow
------------------------------------*/

.shadow-on-hover:hover {
  box-shadow: 0 0 6px 0 rgba(1, 1, 1, 0.3);
}

/*------------------------------------
  Overflow
------------------------------------*/
/*------------------------------------
  Opacity
------------------------------------*/
.opacity-7 {
  opacity: 0.7;
}

/*------------------------------------
  Position Spaces
------------------------------------*/

.top-8 {
  top: 0.499625rem;
}

.top-10 {
  top: 0.546875rem;
}

.left-12 {
  left: 0.749875rem;
}

.bottom-1 {
  bottom: 1rem;
}

.bottom-100 {
  bottom: 100%;
}

.right-1 {
  right: 1rem;
}

.right-2 {
  right: 2rem;
}

/*------------------------------------
  Content Centered
------------------------------------*/
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Vertical Center Alignment */

/*------------------------------------
  Transform
------------------------------------*/

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-9 {
  z-index: 9;
}

/*------------------------------------
  Grid
------------------------------------*/

/*------------------------------------
  Range Slider
------------------------------------*/

/* Indicator */

/*------------------------------------
  File for your custom SCSS style
------------------------------------*/
/* pt-sans-regular - latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../fonts/pt-sans-v17-latin-regular.woff2") format("woff2"), url("../fonts/pt-sans-v17-latin-regular.woff") format("woff");
}
/* pt-sans-italic - latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../fonts/pt-sans-v17-latin-italic.woff2") format("woff2"), url("../fonts/pt-sans-v17-latin-italic.woff") format("woff");
}
/* pt-sans-700 - latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../fonts/pt-sans-v17-latin-700.woff2") format("woff2"), url("../fonts/pt-sans-v17-latin-700.woff") format("woff");
}
/* pt-sans-700italic - latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../fonts/pt-sans-v17-latin-700italic.woff2") format("woff2"), url("../fonts/pt-sans-v17-latin-700italic.woff") format("woff");
}
.loading {
  position: relative;
  pointer-events: none;
  opacity: 0.65;
  transition: all 0 ease-in-out !important;
  z-index: 99;
}
.loading:after {
  background: none;
  content: "" !important;
  position: absolute;
  left: calc(50% - 10px) !important;
  top: calc(50% - 10px) !important;
  width: 20px !important;
  height: 20px !important;
  opacity: 1 !important;
  right: inherit !important;
  z-index: 9;
  text-align: center;
  border: 2px solid rgba(23, 23, 23, 0.2);
  border-top-color: rgba(23, 23, 23, 0.7);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
img.lazy {
  background: #EEE;
}

.navbar-brand.u-header__navbar-brand-vertical img {
  width: 125px;
  padding-top: 0.5rem;
}

.u-header__navbar-brand,
.u-header__navbar-brand > img {
  height: auto;
  width: 110px;
}
@media (min-width: 576px) {
  .u-header__navbar-brand,
  .u-header__navbar-brand > img {
    width: 125px;
  }
}
@media (min-width: 768px) {
  .u-header__navbar-brand,
  .u-header__navbar-brand > img {
    width: 175px;
  }
}
@media (min-width: 992px) {
  .u-header__navbar-brand,
  .u-header__navbar-brand > img {
    width: 237px;
  }
}

.u-header-collapse__nav-link {
  color: #0D4DA1;
}

.js-slide h1,
.js-slide .h1 {
  font-size: 1.85rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .js-slide h1,
  .js-slide .h1 {
    font-size: 2.5rem;
  }
}
.js-slide .product-item {
  margin: 0 auto;
}

.u-slick--slider-syncing .slick-slide {
  border: 0 none;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.67rem 1rem;
}

.btn-blue-cart {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
  color: #FFF;
}
.btn-blue-cart:hover {
  color: #fff;
  background-color: #0a3c7e;
  border-color: #093672;
}
.btn-blue-cart:focus, .btn-blue-cart.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}
.btn-blue-cart.disabled, .btn-blue-cart:disabled {
  color: #fff;
  background-color: #0D4DA1;
  border-color: #0D4DA1;
}
.btn-blue-cart:not(:disabled):not(.disabled):active, .btn-blue-cart:not(:disabled):not(.disabled).active, .show > .btn-blue-cart.dropdown-toggle {
  color: #fff;
  background-color: #093672;
  border-color: #083166;
}
.btn-blue-cart:not(:disabled):not(.disabled):active:focus, .btn-blue-cart:not(:disabled):not(.disabled).active:focus, .show > .btn-blue-cart.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 104, 175, 0.5);
}

@media (max-width: 767.98px) {
  .product-item {
    max-width: 18rem;
  }
}
@media (min-width: 1200px) {
  .product-item:hover .product-item__inner:not(.remove-prodcut-hover) {
    position: relative;
  }
}
.product-item:after {
  content: none !important;
}
.product-item__body .prodcut-price {
  font-size: 1.15rem;
}
.product-item__body .prodcut-price ins,
.product-item__body .prodcut-price del {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.product-item__body .prodcut-price small {
  margin-left: 0.2rem;
}
.product-item__body .prodcut-price ins {
  font-size: 1.1rem;
  color: #333e48;
  text-decoration: none;
}
.product-item__body .prodcut-price del {
  font-size: 0.8rem;
  color: #8f8f8f;
}
.product-item__body .prodcut-add-cart .btn-add-cart {
  padding: 0;
}
.product-item__body .prodcut-add-cart .btn-add-cart.btn-secondary {
  margin-right: 0.25rem;
}
@media (max-width: 1199.98px) {
  .product-item__body .prodcut-add-cart .btn-add-cart {
    width: 1.75rem;
    height: 1.75rem;
  }
  .product-item__body .prodcut-add-cart .btn-add-cart i {
    font-size: 1rem;
  }
}
.product-item__body .prodcut-add-cart-small .btn-add-cart {
  width: 1.75rem;
  height: 1.75rem;
}
.product-item__body .prodcut-add-cart-small .btn-add-cart i {
  font-size: 1rem;
}
.product-item__title a {
  min-height: 1.85rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
@media (min-width: 992px) {
  .product-item__title a {
    min-height: 2.25rem;
  }
}

.btn-add-cart.btn-secondary .ec.ec-favorites {
  margin: 0.1rem 0.05rem 0 0;
}

.section-title:after {
  background-color: #C80101;
}

button[class^=swal2-],
button[class*=" swal2-"] {
  box-shadow: none !important;
}

#addtocart-sticky-container {
  position: relative;
  margin-bottom: 0.5rem;
}
#addtocart-sticky-container.addtocart-sticky-container-fixed {
  transition: all 0.2s ease-in-out;
  position: fixed;
  overflow: hidden;
  z-index: 101;
  background-color: rgb(255, 255, 255);
  box-shadow: #CCC 0px 0px 0.75rem 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  top: 0px;
  border-bottom: 1px solid #ecedf2;
  margin-top: 0;
}
#addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem;
}
@media (min-width: 768px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex {
    padding: 1rem 5%;
  }
}
@media (min-width: 992px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex {
    padding-right: 10%;
    padding-left: 10%;
  }
}
@media (min-width: 1200px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex {
    padding-right: 15%;
    padding-left: 15%;
  }
}
#addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex > div:first-of-type {
  max-width: 80%;
}
@media (min-width: 768px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex > div:first-of-type {
    max-width: 65%;
  }
}
@media (min-width: 1480px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex > div:first-of-type {
    max-width: 80%;
  }
}
#addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex #clonedTitleContainer {
  font-size: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  height: 40px;
  line-height: 1.2;
}
@media (min-width: 768px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex #clonedTitleContainer {
    font-size: 1.125rem;
    -webkit-line-clamp: 1;
    height: 22px;
  }
}
#addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex #clonedPriceContainer {
  font-size: 0.85rem;
}
@media (min-width: 768px) {
  #addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex #clonedPriceContainer {
    font-size: 1rem;
  }
}
#addtocart-sticky-container.addtocart-sticky-container-fixed #addtocart-sticky-container-flex a.btn {
  display: inline-block;
  width: auto;
}